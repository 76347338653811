import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default () => ({
    selectors: {
        inElements: "[data-story-in-animation]",
    },
    inElements: null,
    skipElements: [],

    init() {
        const elms = Array.from(
            this.$el.querySelectorAll(this.selectors.inElements),
        );

        this.$nextTick(() => {
            this.inElements = elms.filter((element) => {
                const parentTab = element.closest("[x-tabs-panel]");
                if (!parentTab) return element;

                const isParentTabEnabled =
                    parentTab.getAttribute("tabindex") !== "-1";

                if (parentTab && isParentTabEnabled) {
                    return element;
                }

                this.skipElements.push(element);
            });

            // sort elements with passed value to attribute first
            this.inElements.sort((a, b) => {
                const aValue = a.getAttribute("data-story-in-animation");
                const bValue = b.getAttribute("data-story-in-animation");

                if (aValue === "" && bValue !== "") return 1;
                if (aValue !== "" && bValue === "") return -1;
                return 0;
            });

            this.setScene();
        });
    },

    destroy() {
        ScrollTrigger.killAll();
    },

    setScene() {
        gsap.set(this.inElements, { opacity: 0 });
        this.initScrollTrigger();
        gsap.set(this.skipElements, { opacity: 1 });
    },

    initScrollTrigger() {
        ScrollTrigger.batch(this.inElements, {
            start: "top bottom-=50",
            once: true,
            batchMax: 9,
            interval: 0.1,
            ease: "power1.inOut",
            onEnter: (batch) => {
                // lets sort element by position in the DOM
                const sortedBatch = [...batch].sort(
                    (a, b) => a.offsetTop - b.offsetTop,
                );

                gsap.fromTo(
                    sortedBatch,
                    { y: 30 },
                    {
                        opacity: 1,
                        y: 0,
                        stagger: 0.1,
                        from: "start",
                        duration: 0.45,
                        ease: "circle.out",
                    },
                );
            },
        });
    },
});
